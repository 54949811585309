.consumptions-chart-container {
  h6 {
    font-weight: bold;
  }
  .consumptions-chart-header {
    i {
      font-size: 14px;
      color: $subtitle-color;
    }
  }
  .consumptions-chart {
    height: calc(85vh - 210px);

    #chartdiv {
      width: 100%;
      height: 100%;
    }
  }
}
