.dataset-legend {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1;

  .color-indicator {
    width: 11px;
    height: 11px;
    border-radius: 2px;
  }
}
