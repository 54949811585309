.user-info-container {
  .flex-column {
    max-width: 100px;
  }
  .user-detail {
    max-width: 150px;
  }
  .user-name {
    overflow: hidden;
    color: $white;
    font-size: 14px;
    font-weight: bold;
  }
  .user-email {
    overflow: hidden;
    font-size: 12px;
    color: $inactive-color;
  }
}
