.block-button {
  position: relative;
  border-radius: 12px;
  height: 120px;
  width: 120px;
  border: 1px solid $primary;
  background: linear-gradient(45deg, rgba(64, 95, 185, 0.4), rgba(56, 173, 192, 0.4));
  .block-info {
    font-size: 10px;
    position: absolute;
    bottom: 8px;
    left: 8px;
    svg {
      color: $neon-yellow;
    }
  }
  &:hover {
    border: 1px solid $secondary;
    background: linear-gradient(45deg, rgba(64, 95, 185, 0.6), rgba(56, 173, 192, 0.6));
  }
  &.block-button-default {
    border: 1px dashed white;
    background: none;
    &:hover {
      border: 1px solid $secondary;
    }
  }
}
