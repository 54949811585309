.error-message {
  background-color: $secondary-dark !important;
}

.success-message {
  background-color: $primary-dark !important;
}

.snackbar-message {
  margin-left: 14px;
}
