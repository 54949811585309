/*Custom Animation*/
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(opacity-appear) {
  0% {
    display: none;
    opacity: 0;
    right: -100px;
  }
  1% {
    display: block;
    opacity: 0;
    right: -100px;
  }
  100% {
    display: block;
    opacity: 1;
    right: 0;
  }
}

@include keyframes(line-grow-and-back-right) {
  0% {
    width: 0px;
  }
  70% {
    width: 60%;
  }
  100% {
    width: 50%;
  }
}

@include keyframes(line-grow-left) {
  0% {
    -webkit-transform: translateX(110%);
    transform: translateX(110%);
    opacity: 0.2;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@include keyframes(percentage-bar) {
  0% {
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@include keyframes(gradient-wave) {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

@include keyframes(wave) {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -950px;
  }
}

@include keyframes(swell) {
  0%,
  100% {
    transform: translate3d(0, -2px, 0);
  }
  50% {
    transform: translate3d(0, 2px, 0);
  }
}

@include keyframes(fill) {
  0% {
    height: 0%;
  }
  25% {
    height: 25%;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 75%;
  }
}

@include keyframes(elevate) {
  0% {
    bottom: 0%;
  }
  25% {
    bottom: 25%;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 75%;
  }
}

@include keyframes(color-change-red) {
  0% {
    color: $radical-red;
  }
  50% {
    color: $radical-red-dark;
  }
  100% {
    color: $radical-red;
  }
}

@include keyframes(color-change-green) {
  0% {
    color: $spring-green;
  }
  50% {
    color: $spring-green-dark;
  }
  100% {
    color: $spring-green;
  }
}

@include keyframes(color-change-blue) {
  0% {
    color: $primary;
  }
  50% {
    color: $primary-dark;
  }
  100% {
    color: $primary;
  }
}

@include keyframes(charging) {
  100% {
    height: 0%;
  }
}
