.table-with-pagination-container {
  tbody tr {
    @extend .contained-section;
    border-radius: 0px !important;
    box-shadow: none;
    border: 1px solid $gray-dark;
    td {
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      padding-left: 28px;
      padding-right: 28px;
      color: $white;
      border-bottom: none;
      a:hover {
        color: $white;
      }
    }
    td:last-child {
      border-right: $gray-dark 1px solid;
    }
  }
  table {
    .highlighted {
      position: relative;
    }
    td:after,
    th:after {
      content: '';
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }
    .highlighted:after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to right, rgba(64, 95, 185, 0.1), rgba(56, 173, 192, 0.1));
      border-left: 1px solid $primary;
      border-right: 1px solid $secondary;
      opacity: 1;
    }
  }
}

.consumptions-table {
  .highlighted {
    position: relative;
  }
  :after,
  :after {
    content: '';
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  .highlighted:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(64, 95, 185, 0.1), rgba(56, 173, 192, 0.1));
    border-left: 1px solid $primary;
    border-right: 1px solid $secondary;
    opacity: 1;
  }

  .MuiDataGrid-footerContainer {
    justify-content: end !important;
  }
}

@include media-breakpoint-down(sm) {
  .table-pagination {
    .MuiToolbar-root {
      .MuiTablePagination-selectRoot,
      .displayed-rows {
        display: none;
      }
    }
  }
}
