.meter-consumption-info-container {
  .meter-consumption-info,
  .meter-charges-info {
    width: 48%;
  }

  @include media-breakpoint-down(md) {
    .meter-info {
      flex-wrap: wrap;

      .meter-consumption-info,
      .meter-charges-info {
        width: 100% !important;
      }

      .meter-charges-info {
        margin-top: 20px;
      }
    }
  }
}
