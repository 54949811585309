@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import './colors';

//------------------------------------- Spacing -------------------------------
$evx-main-container-padding: 32px;
$evx-breadcumbs-container-padding: 0px 0px 16px 0px;

$side-nav-width: 210px !default;
$header-height: 80px !default;
$app-bar-height: 70px !default;
$footer-height: 50px !default;

// ------------------------------------ Elevations ----------------------------
$elevation-shadow-1dp: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  0px 2px 1px -1px rgba(0, 0, 0, 0.12);
$elevation-shadow-2dp: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 3px 1px -2px rgba(0, 0, 0, 0.12);
$elevation-shadow-3dp: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
  0px 3px 3px -2px rgba(0, 0, 0, 0.12);
$elevation-shadow-4dp: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
  0px 1px 10px 0px rgba(0, 0, 0, 0.12);
$elevation-shadow-5dp: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
  0px 1px 14px 0px rgba(0, 0, 0, 0.12);
$elevation-shadow-6dp: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
  0px 1px 18px 0px rgba(0, 0, 0, 0.12);
$elevation-shadow-7dp: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14),
  0px 2px 16px 1px rgba(0, 0, 0, 0.12);
$elevation-shadow-8dp: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12);
$elevation-shadow-9dp: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14),
  0px 3px 16px 2px rgba(0, 0, 0, 0.12);
$elevation-shadow-10dp: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
  0px 4px 18px 3px rgba(0, 0, 0, 0.12);
$elevation-shadow-11dp: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14),
  0px 4px 20px 3px rgba(0, 0, 0, 0.12);
$elevation-shadow-12dp: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
  0px 5px 22px 4px rgba(0, 0, 0, 0.12);
$elevation-shadow-13dp: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14),
  0px 5px 24px 4px rgba(0, 0, 0, 0.12);
$elevation-shadow-14dp: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14),
  0px 5px 26px 4px rgba(0, 0, 0, 0.12);
$elevation-shadow-15dp: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14),
  0px 6px 28px 5px rgba(0, 0, 0, 0.12);
$elevation-shadow-16dp: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
  0px 6px 30px 5px rgba(0, 0, 0, 0.12);
$elevation-shadow-17dp: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14),
  0px 6px 32px 5px rgba(0, 0, 0, 0.12);
$elevation-shadow-18dp: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14),
  0px 7px 34px 6px rgba(0, 0, 0, 0.12);
$elevation-shadow-19dp: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14),
  0px 7px 36px 6px rgba(0, 0, 0, 0.12);
$elevation-shadow-20dp: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
  0px 8px 38px 7px rgba(0, 0, 0, 0.12);
$elevation-shadow-21dp: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14),
  0px 8px 40px 7px rgba(0, 0, 0, 0.12);
$elevation-shadow-22dp: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14),
  0px 8px 42px 7px rgba(0, 0, 0, 0.12);
$elevation-shadow-23dp: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14),
  0px 9px 44px 8px rgba(0, 0, 0, 0.12);
$elevation-shadow-24dp: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
  0px 9px 46px 8px rgba(0, 0, 0, 0.12);
