.status-led-container {
  .status-led {
    border-radius: 100px;

    &.is-online {
      background-color: $success;
    }

    &.is-waiting {
      background-color: $alert;
    }

    &.is-offline {
      background-color: $error;
    }

    &.is-disabled {
      background-color: gray;
    }
  }
}
