.section-title-container {
  overflow: hidden;
  &:after {
    display: block;
    border-radius: 10px;
    height: 2px;
    margin-top: 3px;
    content: '';
    background-color: $secondary-light;
    @include animation('line-grow-left 0.3s ease-out');
  }
}
