.header-container {
  top: 0;
  right: 0;
  width: 100%;
  padding: 16px 0px;

  .sidenav-button {
    height: 38px;
    width: 38px;
  }

  .header-title {
    font-weight: bold;
  }

  .header-desc {
    color: $subtitle-color;
  }

  svg {
    font-size: 18px;
    color: $font-base-color;
  }

  button {
    padding: 6px;
    display: none;
  }

  .search {
    * {
      display: none;
    }
    font-size: 14px;
    color: $subtitle-color;
  }
}
