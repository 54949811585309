.right-button-wrapper {
  position: absolute;
  top: 50%;
  right: -14px;
  z-index: 1;
  background-color: $pelorous-blue;
  border-radius: 4px 4px 0px 0px;
  font-size: 14px;
  transform: rotate(-90deg);
  button {
    color: $white;
  }
}
