.meter-display-container {
  padding: 16px;
  width: 100%;

  .meter-display-legend {
    .meter-square {
      height: 16px;
      width: 16px;
    }
    span {
      font-size: 12px;
    }
    // padding: 8px;
    // border: 1px solid $gray;
    // border-radius: 4px;
  }

  .meters-container {
    padding: 32px 32px;
    // padding-bottom: 30px;
    border: 1px solid $gray-darkest;
    background: linear-gradient(45deg, rgba(141, 141, 141, 0.1) 0%, rgba(48, 46, 53, 0.1) 100%);
    border-radius: 8px;

    .status-chart-container {
      height: 100px;
      width: 100% !important;
      max-width: 500px;

      .meter-count {
        padding-top: 2px;
        span {
          width: max-content;
          text-align: right;
          &:nth-child(2) {
            margin: 6px 0px;
          }
        }
      }
    }

    .MuiPaper-root,
    .MuiAccordion-root:before {
      box-shadow: none;
      background-color: transparent;
    }

    .meter-monitor-container {
      overflow: hidden;
      height: 0px;
      transition: 0.8s ease;

      &.expanded {
        height: 100px;
        transition: 0.8s ease;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .meters-container {
      padding-left: 0px;
      padding-right: 0px;
      div {
        &:nth-child(1) {
          flex-direction: column !important;
          text-align: center;
        }
      }

      .status-chart-container {
        margin-top: 30px;

        div {
          &:nth-child(1) {
            flex-direction: row !important;
          }
        }
      }

      .meter-monitor {
        flex-direction: column !important;
        padding-left: 20px;

        div {
          &:nth-child(1) {
            flex-direction: row !important;
          }
        }

        button {
          margin-top: 20px;
        }
      }
    }
  }
}

.meter-square {
  margin-top: 6px;
  margin-right: 6px;
  width: 18px;
  height: 18px;
  background-color: red;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }

  &.is-selected {
    opacity: 1;
  }

  &.meter-online {
    background-color: $success;
  }

  &.meter-offline {
    background-color: $error;
  }

  &.meter-stop {
    background-color: $alert;
  }
}

@include media-breakpoint-down(md) {
  .status-chart-wrapper {
    height: 100px;
    width: 100% !important;
    max-width: 500px;
  }
  .chartjs-render-monitor {
    width: 100% !important;
  }
}
