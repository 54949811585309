/* Base Styles */
html {
  height: 100vh;
  overflow: hidden;
}

:root {
  font-size: 16px;
}

body {
  height: 100%;
  overflow: hidden;
}

.no-decoration {
  text-decoration: none !important;
}

body.ios-mobile-view-height {
  // height: auto !important;
  overflow: visible !important;
}

.app-login-container {
  color: $font-base-alt-color !important;
  width: 100%;
  height: 90vh;

  .app-login-form {
    .MuiCheckbox-root {
      color: $secondary;
    }
  }

  .MuiFormLabel-root {
    color: $inactive-color;
  }
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: $inactive-color;
    }
  }
  input {
    color: $font-base-alt-color;
  }
  .logo-container {
    a img {
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 1;
      height: 50px;
    }

    // &:after {
    //   position: absolute;
    //   content: '';
    //   width: 0;
    //   right: 0;
    //   height: 0;
    //   bottom: 0;
    //   border-left: 180px solid transparent;
    //   border-bottom: 180px solid $white;
    //   -moz-transform: scale(0.999);
    //   -webkit-backface-visibility: hidden;
    // }
  }
  .app-login-content {
    min-width: 320px;
    max-width: 367px;
    padding: 32px;

    .MuiButton-contained.Mui-disabled {
      background-color: dimgray !important;
    }
  }
  .app-login-main-content {
    background-color: $white;
    border-radius: 16px;
    display: flex;
    flex-direction: row-reverse;
    .app-logo-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 32px;
      background-color: $bg-color;
      a img {
        height: 80px;
      }
    }
  }
}

.app-header,
.app-header .app-main-header,
.app-header .app-toolbar {
  background: $bg-color;
  min-height: 56px;
  height: 56px;
}

/* Spinner and page progress bar */
#nprogress .bar {
  z-index: 5000 !important;
  background: $secondary !important;
}

#nprogress .peg {
  @include box-shadow(0 0 10px $secondary, 0 0 5px $secondary !important);
}

#nprogress .spinner {
  z-index: 5000 !important;
}

#nprogress .spinner-icon {
  border-top-color: $secondary !important;
  border-left-color: $secondary !important;
}

// Scrollbar
// Chrome and Safary
*::-webkit-scrollbar {
  width: 4px; /* width of the entire scrollbar */
  height: 4px;
}
*::-webkit-scrollbar-track {
  background: $primary-lightest; /* color of the tracking area */
}
*::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, rgba(64, 95, 185, 0.8) 0%, rgba(56, 173, 192, 0.8) 100%);
  border-radius: 40px; /* roundness of the scroll thumb */
}

// Firefox
* {
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: $subtitle-color $border-color; /* scroll thumb & track */
}

.close-button {
  position: absolute !important;
  color: $subtitle-color !important;
  top: 8px;
  right: 8px;
  height: 10px;
  width: 10px;
}

.contained-section {
  // border: 2px solid $gray-dark;
  box-shadow: $elevation-shadow-1dp;
  background-color: rgba(25, 25, 28, 0.3);
  border-radius: 4px;
  padding: 24px;
  h6 {
    font-weight: bold;
  }
}

.form-subtitle {
  color: $secondary-lightest;
  width: 100%;
  border-bottom: 1px solid $secondary-dark;
  margin-bottom: 24px;
}

.no-data-message {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
}

.subtitle {
  color: $subtitle-color;
}

.line-height-1 {
  line-height: 1;
}

.max-height-300 {
  max-height: 300px;
}

.text-box-md {
  max-width: 150px;
}

.page-heading {
  & .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent !important;
    margin-bottom: 0;
    padding: 0px;

    .breadcrumb-item + .breadcrumb-item:before {
      color: $inactive-color;
      content: '—';
    }

    &-item {
      color: $inactive-color;

      &.active {
        color: $font-base-color;
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
  }
}

.pointer {
  cursor: pointer;
}

// plug icon
.icon-blue-static {
  color: $primary-dark;
}

.icon-red {
  color: $secondary;
  margin-right: 2px;
  @include animation('color-change-red 2s ease-in-out infinite');
}

.color-blue {
  color: $primary;
}

.color-green {
  color: $success;
}

.color-yellow {
  color: $wax-yellow;
}

.color-white {
  color: $font-base-color;
}

.color-red {
  color: $secondary;
}

.description-text {
  max-width: 600px;
  font-size: 16px;
  // color: $gray-light;
}

// disabled a element
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.disabled > a {
  display: inline-block;
  pointer-events: none;
  text-decoration: none;
}

@media print {
  @page {
    size: auto;
    margin: 0mm;
  }

  .app-header,
  .side-nav {
    display: none !important;
  }
  .app-main-content-wrapper {
    padding: 0px !important;
  }
}

.width-auto {
  width: auto;
}

// Font sizes
.font-size-4 {
  font-size: 4px !important;
}

.font-size-6 {
  font-size: 6px !important;
}

.font-size-8 {
  font-size: 8px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 24px !important;
}

.font-size-28 {
  font-size: 24px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-34 {
  font-size: 34px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-42 {
  font-size: 42px !important;
}

.font-size-44 {
  font-size: 44px !important;
}

.font-size-46 {
  font-size: 46px !important;
}

.font-size-48 {
  font-size: 48px !important;
}

.w-20 {
  width: 20% !important;
}

// Square sizes
.size-10 {
  width: 10px !important;
  height: 10px !important;
}

.size-20 {
  width: 20px !important;
  height: 20px !important;
}

.size-30 {
  width: 30px !important;
  height: 30px !important;
}

.size-40 {
  width: 40px !important;
  height: 40px !important;
}

.size-50 {
  width: 50px !important;
  height: 50px !important;
}

.size-60 {
  width: 60px;
  height: 60px;
}

.size-70 {
  width: 70px;
  height: 70px;
}

.size-80 {
  width: 80px;
  height: 80px;
}

.size-90 {
  width: 90px;
  height: 90px;
}

.size-100 {
  width: 100px;
  height: 100px;
}

.size-110 {
  width: 110px;
  height: 110px;
}

.size-120 {
  width: 120px;
  height: 120px;
}

.bg-blue {
  background-color: $primary !important;
}

.bg-white {
  background-color: $font-base-color;
}

.bg-transparent {
  background-color: transparent;
}

.bg-red {
  background-color: $secondary !important;
}

.positive-number {
  color: $spring-green;
}

.negative-number {
  color: $secondary;
}

.color-success {
  color: $success;
}

.color-alert {
  color: $alert;
}

.color-danger {
  color: $danger;
}

.button-danger {
  color: $white !important;
  background-color: $error !important;
}

#hubspot-messages-iframe-container {
  z-index: 1299 !important;
  margin-right: 12px;
  margin-bottom: 12px;
  @media screen and (max-width: 575px) {
    margin-right: 0px;
  }
}

// Custom Snackbar
.custom-snackbars-container {
  position: fixed;
  z-index: 1401;
  bottom: 24px;
  right: 24px;
}

.custom-snackbar {
  position: relative !important;
  bottom: 0 !important;
  right: 0 !important;
  margin-top: 12px;
}

// Custom FAB Onboarding
.custom-onboarding-fab {
  position: fixed;
  z-index: 1300;
  top: 24px;
  right: 24px;
}

.introjs-tooltip {
  background-color: $secondary-dark !important;
}

.introjs-tooltiptext {
  padding: 0px 18px !important;
  overflow-y: auto;

  @media screen and (max-width: 769px) {
    max-height: 300px;
  }
}

.introjs-tooltip-header {
  padding: 12px 8px !important;
}

.introjs-skipbutton {
  font-size: 14px !important;
  color: $secondary-darker !important;
}

.introjs-bullets {
  position: absolute;
  height: 56.6px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ul {
    margin: 0px 0px 0px 32px !important;
  }
  a {
    background-color: $secondary-dark !important;
    &.active {
      border: 1px solid $secondary-lighter;
      background-color: $white !important;
    }
  }
}

.introjs-tooltipbuttons {
  border-top: none !important;
  a {
    border: none !important;
    background: transparent !important;
    text-shadow: none !important;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.9);

    &:focus,
    &:hover {
      color: $white;
    }
    &.introjs-disabled {
      color: rgba(255, 255, 255, 0.4) !important;
    }
  }
}

.introjs-helperLayer {
  box-shadow: rgba(56, 173, 192, 0.5) 0px 0px 1px 2px, rgba(42, 63, 67, 0.5) 0px 0px 0px 5000px !important;
}

.introjs-tooltip-header {
  border-radius: 5px 5px 0px 0px !important;
}

.introjs-tooltip-header,
.step-tooltip-top {
  background-color: #d7fafe !important;
}

.introjs-arrow {
  &.left {
    border-right-color: #d7fafe !important;
  }
  &.right {
    border-left-color: #d7fafe !important;
  }
  &.top {
    border-bottom-color: #d7fafe !important;
  }
  &.bottom {
    border-top-color: #d7fafe !important;
  }
}

.introjs-tooltipReferenceLayer {
  z-index: 1300 !important;
}

div.introjs-showElement,
.introjs-showElement {
  z-index: 1299 !important;
}

.introjs-overlay {
  z-index: 1298 !important;
}

.introjs-helperLayer {
  z-index: 1297 !important;
}

.step-tooltip-top {
  margin: -12px -18px 0px -18px;
  padding: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 769px) {
    max-height: 180px;

    svg {
      max-height: 120px !important;
    }
  }
}

.step-tooltip-bottom {
  padding: 24px 8px 8px 8px;

  @media screen and (max-width: 769px) {
    max-height: 200px;
  }
}

.steps-big {
  max-width: 360px !important;
  width: 420px;
  max-height: 100vh;
  .step-tooltip-top {
    margin: -12px -18px 0px -18px;
    padding: 24px;
    svg {
      max-height: 250px;
    }
  }

  .step-tooltip-bottom {
    padding: 36px 16px 8px 16px;
  }

  @media screen and (max-width: 376px) {
    width: 280px;
  }
}

.update-snackbar {
  display: flex;
  z-index: 9999;
  background-color: #fafafa;
  color: black;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 0.5px 0.5px 10px 0.5px rgba(0, 0, 0, 0.8);
  // opacity: 1;
  transition: 0.3s ease-out;
  max-width: 700px;
  width: 90%;

  &.hidden {
    // opacity: 0;
    display: none;
    transition: 0.3s ease-in;
  }

  button {
    outline: none;
    background: none;
    border: none;

    &:hover {
      color: #584dbb;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 80px;

    button {
      margin-top: 14px;
      margin-left: 0px !important;
    }
  }
}

.hide-label {
  label {
    display: none !important;
  }
}
