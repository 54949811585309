@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/utilities';

.filter-bar-container {
  // height: 100%;
  display: flex;
  justify-content: space-between;

  h6 {
    font-weight: bold;
    font-size: 12px;
  }

  .select-element {
    width: 50%;
  }

  .date-element {
    width: 50%;
  }

  .clear-button {
    font-size: 12px;
    color: $subtitle-color;
    &:hover {
      cursor: pointer;
    }
  }

  .dates-section {
    background-color: $white;
    border: 1px $inactive-color solid;
    color: $inactive-color;
    border-radius: 8px;
    padding: 0px 4px;

    .dates-title {
      width: 60px;
      font-size: 12px;
    }

    .MuiInputBase-inputAdornedEnd {
      padding: 4px 0px !important;
    }

    .MuiIconButton-root {
      padding: 0px !important;
    }
  }

  .filter-bar {
    justify-content: flex-start;
    align-items: flex-start !important;
    display: flex !important;
    flex-direction: row !important;
    @extend .col-md-12, .col-lg-8;

    .filter-group-container {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      padding: 0px;
      margin-bottom: 0px !important;

      &:nth-child(1) {
        margin-right: 16px;
      }
    }
  }

  .filter-bar-action-buttons {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-end !important;
    @extend .col-md-12, .col-lg-4, .mt-md-0;

    .search-button {
      margin-left: 10px;
      max-width: 44px;
      @extend .px-sm-0, .col-md-2, .col-sm-6;
    }

    .download-button {
      min-width: 140px;
    }

    .pre-income-button {
      min-width: 100px;
      max-width: 100px;
    }
  }
}

@include media-breakpoint-down(md) {
  .filter-bar-container {
    justify-content: space-between;

    .filter-bar {
      .filter-group-container {
        margin-bottom: 10px !important;
      }
    }

    .filter-bar-action-buttons {
      flex-direction: row !important;

      .pre-income-button {
        min-width: 100px;
        max-width: 100px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .filter-bar-container {
    flex-direction: column !important;

    .filter-bar {
      flex-direction: column !important;

      .filter-group-container {
        width: 100%;

        .select-element {
          width: 100%;
        }

        .date-element {
          width: 100%;
        }
      }
    }

    .filter-bar-action-buttons {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;

      .search-button,
      .download-button,
      .pre-income-button {
        width: 100% !important;
        margin-top: 10px;
      }

      div.button-tooltip {
        width: 100%;

        .pre-income-button {
          min-width: none !important;
          max-width: none !important;
          width: 100% !important;
          margin-top: 10px;
        }
      }

      .search-button {
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        max-width: none;
      }
    }
  }
}
.search-button {
  max-height: 40px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
