.tabs-container {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  border-bottom: solid 1px $inactive-color;

  &.consumption-energy-tabs {
    margin-bottom: 24px;
  }
}

.tabs-header {
  position: relative;
}

.tabs-header .tab {
  position: relative;
  font-size: 14px;
  color: $inactive-color;
  &:after {
    display: block;
    content: '';
    border-bottom: solid 4px $secondary;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover {
    color: $font-base-color;
  }
  &.is-selected {
    color: $font-base-color;
    &:after {
      transform: scaleX(1);
    }
  }
}
