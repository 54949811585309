.right-panel-wrapper {
  position: relative;
  background-color: $right-panel-bg-color;
  border-left: 1px solid $border-color;
  min-width: 250px;
  width: 250px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  transition: margin-right 250ms ease-in-out, opacity 250ms ease-in-out;

  &.open {
    opacity: 1;
    margin-right: 0px;
  }

  &.close {
    opacity: 0;
    margin-right: -250px;
  }

  .close-panel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    position: absolute;
    top: 8px;
    left: 8px;
    color: $subtitle-color;
    height: 25px;
    width: 25px;
    border-radius: 20px;
    &:hover {
      color: $black;
      cursor: pointer;
    }
  }
}
