.last-seven-days-chart-container {
  h6 {
    font-weight: bold;
  }
  .last-seven-days-chart {
    height: 350px;

    #chartdiv {
      width: 100%;
      height: 100%;
    }
  }
}
