.administration-view-container {
  .MuiTablePagination-root {
    margin-right: 50px;
  }

  .users-table {
    .search-input-container {
      max-width: 320px;
      min-width: 240px;
    }
  }

  .meters-table {
    .search-input-container {
      max-width: 260px;
      min-width: 220px;
    }
  }

  @include media-breakpoint-down(md) {
    .users-table {
      div.position-absolute {
        width: 100%;
        left: 16px !important;
      }

      .search-input-container {
        margin-top: 50px !important;
      }
    }

    .meters-table {
      div.action-bar {
        left: 16px !important;
      }

      .MuiDataGrid-cell {
        &.MuiDataGrid-cell:focus-within {
          outline: none !important;
        }
      }

      .MuiDataGrid-columnHeader {
        &.MuiDataGrid-columnHeader:focus-within {
          outline: none !important;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .proration-form-dist-container,
    .proration-form-supply-container,
    .proration-form-igx-container {
      div.row.mb-3 {
        flex-direction: column !important;
        margin-bottom: 0px !important;

        div.col-3 {
          max-width: none;
          margin-bottom: 20px !important;
        }
      }
    }

    .filter-bar-container {
      .select-element {
        margin-right: 0px !important;
      }
    }
  }
}

.meters-table,
.users-table {
  .position-absolute {
    right: 16px;
    top: 4px;
    z-index: 1;
  }

  .search-input-container {
    margin-bottom: 16px;
  }

  @include media-breakpoint-down(md) {
    .meters-table-selected {
      justify-content: space-between !important;

      .meters-table-selected-btn {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        button {
          margin-right: 0px !important;
        }

        button:first-child {
          margin-bottom: 8px !important;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .meters-table-selected {
      flex-direction: column-reverse;
      justify-content: space-between !important;

      span.mr-2 {
        margin-top: 10px;
        margin-right: 0px !important;
      }

      .meters-table-selected-btn {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;

        button {
          width: 100%;

          &:nth-child(2) {
            margin-top: 6px !important;
          }
        }
      }
    }
  }
}

.meters-table {
  .search-input-container {
    @include media-breakpoint-down(md) {
      margin-top: 90px !important;
      width: 30%;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 140px !important;
      width: 100%;
      max-width: 240px;
    }
  }
}

.edit-user-form-container {
  padding: 24px;
  .MuiFormControl-root {
    // max-width: 160px;
  }
  .edit-form-select {
    .MuiInputBase-root {
      height: 100%;
    }
    .MuiFormLabel-root {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}

.assign-meters-panel-container {
  padding: 24px;

  .assign-meters-content-panel {
    background-color: $gray-dark;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $gray-darker;
  }

  .description-text {
    border-bottom: 1px solid $white;
  }

  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 16.5px 14px !important;
    }
  }

  .meter-selector-container {
    // .block-button {
    //   position: relative;
    //   height: 120px;
    //   width: 120px;
    //   border: 1px solid $primary;
    //   background: linear-gradient(45deg, rgba(64, 95, 185, 0.4), rgba(56, 173, 192, 0.4));
    //   .block-info {
    //     font-size: 10px;
    //     position: absolute;
    //     bottom: 8px;
    //     left: 8px;
    //     svg {
    //       color: $neon-yellow;
    //     }
    //   }
    // }
  }
}
